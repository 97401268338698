a {
  text-decoration: none;
}

[data-amplify-authenticator].hidden {
  display: none;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
}

@keyframes pulse-animation {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
.pulse {
  animation: pulse-animation 2s ease-in-out infinite;
}